<template>
    <b-card-code
        title="Add New Service Package"
    >
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            label="Coupon Title"
                            label-for="coupon-title">
                            <validation-provider
                                #default="{ errors }"
                                name="Coupon Title"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="couponCodeInfo.title"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Coupon Title"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Coupon Code"
                            label-for="coupon-code">
                            <validation-provider
                                #default="{ errors }"
                                name="code"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="couponCodeInfo.code"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Coupon Code"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Discount Amount"
                            label-for="discount-amount">
                            <validation-provider
                                #default="{ errors }"
                                name="discount"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="couponCodeInfo.discount"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Discount Amount"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Max Uses"
                            label-for="max">
                            <validation-provider
                                #default="{ errors }"
                                name="max_uses"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="couponCodeInfo.max_uses"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Max Uses"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Expires Date"
                            label-for="expires_at">
                            <validation-provider
                                #default="{ errors }"
                                name="expires_at"
                                rules="required"
                            >
                                <b-form-datepicker
                                    id="expires_at"
                                    v-model="couponCodeInfo.expires_at"
                                    class="mb-1"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <validation-provider
                                #default="{ errors }"
                                name="Status"
                                rules="required"
                            >
                                <b-form-select
                                    v-model="couponCodeInfo.status"
                                    :options="status"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                            type="submit"
                            variant="primary"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
    BFormDatepicker, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormSelect,
} from 'bootstrap-vue'
import {required} from '@validations'
import useJwt from "@/auth/jwt/useJwt";
import Mixin from "@core/services/mixin";
import {checkRes} from "@core/services/helper";
import {ADMIN_COUPON_CODE_DETAIL} from "@core/services/api";
import router from "@/router";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormDatepicker,
        BFormSelect,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            couponCodeId:router.currentRoute.params.ccId,
            couponCodeInfo: 'null',
            title: null,
            code: null,
            discount: null,
            uses: null,
            max_uses: null,
            expires_at: null,
            selected: null,
            status: [
                {value: null, text: 'Please select status'},
                {value: 1, text: 'Active'},
                {value: 0, text: 'Inactive'}
            ],
            required,
        }
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    useJwt.createCouponCodeAdmin({
                        id:this.couponCodeId,
                        title: this.couponCodeInfo.title,
                        code: this.couponCodeInfo.code,
                        discount: this.couponCodeInfo.discount,
                        max_uses: this.couponCodeInfo.max_uses,
                        expires_at: this.couponCodeInfo.expires_at,
                        status: this.couponCodeInfo.status,
                    }).then(response => {
                        if (checkRes(response.data.code)){
                            console.log(response.data.code);
                            this.$router.push({name: 'admin-coupon-code-list'})
                                .catch(error => {
                                    this.toastMessage('success','Coupon Code', response)
                                })
                        } else {
                            this.$router.push({name: 'admin-coupon-code-list'})
                                .catch(error => {
                                    this.toastMessage('warning','Coupon Code', response)
                                })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        getServicePackageDetail() {
            this.$http.get(process.env.VUE_APP_BASEURL+  ADMIN_COUPON_CODE_DETAIL + this.couponCodeId)
                .then(res => {
                    this.couponCodeInfo = res.data.data.coupon_code_details
                }).catch(error => {
                console.log(error)
            })
        },
    },
    mounted() {
        this.getServicePackageDetail()
    }
}
</script>
